<template>
  <div class="loader-container" v-if="isLoading">
    <span class="loader"></span>
  </div>
  
  <apexchart
    type="line"
    height="450"
    :options="clubCarbyResults.resultsChartOptions"
    :series="clubCarbyResults.series"
    v-else
  ></apexchart>
</template>

<script setup>
import { ref, defineProps, onMounted, watch } from "vue";
import { formatCurrencyWithoutSymbol, getMonth } from "@/mixins";
import { ManagerDashboardController } from "@/AppClubCarby/Controller/DashBoardsController";

const isLoading = ref(true);

const managerDashboardService = new ManagerDashboardController(
  props.managerId,
  props.periodStart,
  props.periodEnd,
  props.gasStationId
);

const props = defineProps({
  managerId: {
    type: Number,
    required: true,
  },
  periodStart: {
    type: String,
    required: true,
  },
  periodEnd: {
    type: String,
    required: true,
  },
  gasStationId: {
    type: Number,
    required: true,
  },
});

const chartData = ref([]);

const clubCarbyResults = ref({
  series: [
    {
      name: "Gasolina",
      type: "column",
      data: [],
      color: "#11592E",
    },
    {
      name: "Gasolina Aditivada",
      type: "column",
      data: [],
      color: "#4A8A2E",
    },
    {
      name: "Diesel",
      type: "column",
      data: [],
      color: "#FFC145",
    },
    {
      name: "Etanol",
      type: "column",
      data: [],
      color: "#555",
    },
    {
      name: "Galonagem",
      type: "line",
      data: [],
      color: "#000",
    },
  ],
  resultsChartOptions: {
    chart: {
      type: "line",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [1, 1, 1, 1, 4],
    },
    dataLabels: {
      background: {
        foreColor: "white",
        borderWidth: 0,
        padding: 0,
      },
      style: {
        colors: ["black"],
        fontSize: "12px",
      },
      hideOverlappingLabels: true,
      enabled: true,
      enabledOnSeries: [4],
      formatter: (val) => {
        return formatCurrencyWithoutSymbol(val);
      },
    },
    labels: [],
    yaxis: {
      title: {
        text: "Galonagem (litros)",
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "16px",
        },
      },
      forceNiceScale: true,
      tickAmount: 6,
      decimalsInFloat: 0,
    },
    legend: {
      horizontalAlign: "left",
      position: "bottom",
      offsetX: 10,
      markers: {
        radius: 7,
        offsetX: 0,
      },
      itemMargin: {
        horizontal: 15,
        vertical: 0
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "left",
        },
      },
    },
  },
  set setLabels(data) {
    this.resultsChartOptions.labels = data;
  },
  setSeries(index, data) {
    this.series[index].data = data;
  },
});

const updateClubCarbyChart = () => {
  const gasolineData = chartData.value.map((data) => {
    return data.gasoline;
  });

  const gasolineAdditiveData = chartData.value.map((data) => {
    return data.gasolineAdditive;
  });

  const dieselData = chartData.value.map((data) => {
    return data.diesel;
  });

  const ethanolData = chartData.value.map((data) => {
    return data.ethanol;
  });

  const resultLabels = chartData.value.map((data) => {
    return getMonth("Full", data.yearMonth[1]);
  });

  const amountData = chartData.value.map((data) => {
    return data.sum;
  });

  clubCarbyResults.value.setSeries(0, gasolineData);
  clubCarbyResults.value.setSeries(1, gasolineAdditiveData);
  clubCarbyResults.value.setSeries(2, dieselData);
  clubCarbyResults.value.setSeries(3, ethanolData);
  clubCarbyResults.value.setSeries(4, amountData);
  clubCarbyResults.value.setLabels = resultLabels.filter((el, pos) => {
    return resultLabels.indexOf(el) == pos;
  });
};

onMounted(() => {
  getData();
});

const getData = async () => {
  chartData.value = await managerDashboardService.fuelLitersData();
  updateClubCarbyChart();
  isLoading.value = false;
};

watch(props, (newVal, oldVal) => {
  if (newVal.toString() != oldVal.toString()) {
    getData();
  }
});
</script>

<style lang="scss" scoped>
.loader-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #11592e;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
